.header {

}

.header-logo {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.header-logo-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  width: 300px;
  margin: auto;
  text-align: center;
  cursor: pointer;
}
