body {
  font-family: 'Lato', sans-serif;
}

.navbar {
  background-color: var(--color3);
  display: flex;
  align-items: center;
}

.navbar-big {
  display: none;
}

.span-open-nav {
  font-size: 40px;
  cursor: pointer;
  color: var(--color1);
}

.fa-bars {
  padding-left: 20px;
  padding-bottom: 10px;
  font-size: 30px;
}

.overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--color3);
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.overlay-content-bar:hover {
  background-color: rgb(140, 0, 0)
}

/* Borrar? */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: var(--color1);
  display: block;
  transition: 0.3s;
}

/* Nuevo */
.overlay button {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: var(--color1);
  display: block;
  transition: 0.3s;
  background-color: var(--color3);
  width: 100%;
  margin: 0px;
}

/* Borrar? */
.overlay a:hover,
.overlay a:focus {
  background-color: var(--color1);
  color: var(--color3);
}

/* Nuevo */
.overlay button:hover,
.overlay button:focus {
  background-color: var(--color1);
  color: var(--color3);
  box-shadow: none;
}


.overlay .closebtn {
  position: absolute;
  text-align: end;
  padding-top: 20px;
  padding-right: 45px;
  font-size: 60px;
  cursor: pointer;
  background-color: var(--color4);
}

.overlay .closebtn:hover {
  color: var(--color1);
  background-color: rgb(140, 0, 0)
}

.custom-select {
  background-color: var(--color3);
  color: var(--color1);
  cursor: pointer;
  border-style: none;
  font-size: 15px;
  font-weight: bold;
}

.option {
  background-color: var(--color3);
}

.other-languages-left {
  color: var(--color1);
  position: absolute;
  right: 105px;
  cursor: pointer;
  font-size: large;
  font-size: 15px;
  font-weight: bold;
}

.vl {
  border-left: 2px solid white;
  height: 30px;
  position: absolute;
  right: 85px
}

.other-languages-right {
  color: var(--color1);
  position: absolute;
  right: 5px;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
}

@media screen and (max-height: 450px) {
  .overlay button {
    font-size: 20px
  }

  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

@media only screen and (min-width: 1000px) {

  .navbar {
    display: none;
  }

  /* Add a black background color to the top navigation */
  .navbar-big {
    background-color: var(--color3);
    height: 50px;
    display: block;
    overflow: hidden;
  }

  /* Style the links inside the navigation bar */
  .navbar-big a {
    float: left;
    color: var(--color1);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
  }

  .navbar-big button {
    float: left;
    color: var(--color1);
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;

    margin: 0px
  }

  /* Change the color of links on hover */
  .navbar-big a:hover, .navbar-big button:hover {
    background-color: var(--color1);
    color: var(--color3);
    box-shadow: none;
  }

  /* Add a color to the active/current link */
  .navbar-big a.active {
    background-color: #04AA6D;
  }

  .navbar-big a.split, .navbar-big button.split{
    float: right;
    padding-top: 16px;
    font-size: 15px;
    height: 51px;
  }

  .other-languages-left {
    right: 105px;
    font-size: large;
    font-size: 15px;
    font-style: italic;
  }

  .other-languages-right {
    right: 15px;
    font-size: 15px;
    font-style: italic;
  }
}