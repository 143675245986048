:root {
  --color0: black;
  --color1: white;
  --color2: #E5E5E5;
  --color3: rgb(139, 0, 0);
  --color4: rgba(139, 0, 0, 0.9);
  --color5: rgb(247, 196, 67);
  --color6: dimgrey;
}

body {
  height: 100vh;
}

.App {
  height: 100vh;
  min-width: 300px;
}

.main {
  min-width: 280px;
  min-height: 100vh;
  background-image: url('./images/images-other/beach.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  /* overflow: hidden; */
}

.disabled-link {
  color: #007bff; /* Link color */
  text-decoration: underline; /* Underline to make it look like a link */
  pointer-events: none; /* Disable clicking */
  cursor: default; /* Change cursor to default */
}

.mainpage-logo {
  margin-top: 75px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.mainpage-logo-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  width: 350px;
  margin: auto;
  text-align: center;
}

.languages {
  padding: 50px 0px 0px 0px;
}

.language {
  padding: 10px 20px 10px 20px;
  color: var(--color1);
  font-family: Georgia Italic;
  font-size: 35px;
  font-weight: 800;
  max-width: 250px;
  margin: 30px auto 30px auto;
  border: 3px solid white;
  text-align: center;
}

.language:hover {
  /* box-shadow: 5px 5px white; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 2.2), 0 6px 20px 0 rgba(0, 0, 0, 3.19);
  cursor: pointer;
}

.social {
  color: var(--color1);
  text-align: center;
  font-size: 40px;
  justify-content: center;
  margin-top: 50px;
}

.fa-social {
  margin: 10px;
  padding: 10px 30px 10px 30px
}

.fa-solid:hover,
.fa-brands:hover {
  color: var(--color2);
}

.background-img-acknowledgment {
  margin-top: auto;
  text-align: end;
  color: var(--color1);
  padding: 10px;
}

#main-acknow-link {
  color: var(--color1);
}

#main-social-link {
  color: var(--color1);
}

#li-a-black {
  text-decoration: underline;
  cursor: pointer;
  background-color: transparent;
  color: black;
  margin: 0px 2px 0px 2px;
  padding: 0px;
  font-size: 18px;
}

#li-a-black:hover {
  color: var(--color0);
  cursor: pointer;
  box-shadow: none;
}

@media only screen and (min-width: 1000px) {

  .disabled-link {
    color: #007bff; /* Link color */
    text-decoration: underline; /* Underline to make it look like a link */
    pointer-events: none; /* Disable clicking */
    cursor: default; /* Change cursor to default */
  }

  .languages {
    padding-top: 75px;
    display: flex;
    flex-direction: row;
  }

  .language {
    font-size: 45px;
    max-width: 275px;
  }

  .social {
    margin-top: 75px;
  }
}

