.message-box {
  
  max-width: 300px;
  margin: auto;
  margin-top: 20px;
  color: white;
  font-weight: 600;
  background-color: black;
  padding: 20px;
  text-align: center;
  border-radius: 20px;

}

@media only screen and (min-width: 600px) { 
.message-box {
  max-width: 600px;
}


}