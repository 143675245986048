.shop {
  margin-top: 50px;
display: flex;
flex-direction: column;
text-align: center;
}

.shop-lessons {
  display: flex;
  flex-direction: column;
}

.shop-button {
  background-color: var(--color5);
  color: var(--color0);
  width: 275px;
}

@media only screen and (min-width: 1000px) {
  .shop-lessons {
    flex-direction: row;
    justify-content: center;
  }
}