.about {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.about-picture {
height: auto;
max-height: 250px;
padding: 25px;
max-width: 100%;
border-radius: 50px;
margin: auto;
display: block;
}

.about-text {
  padding: 25px;
  margin: auto;
  font-size: 18px;
  text-align: justify;
} 

#text-link {
color: black
}

@media only screen and (min-width: 1000px) {
  .about {
    margin-top: 10px;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }

  .about-picture {
    margin: 0px;
  }

  .about-text {
    margin: 0px;
  }
}
