.home {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.home-picture {
  height: auto;
  max-height: 250px;
  padding: 25px;
  max-width: 100%;
  border-radius: 50px;
  margin: auto;
  display: block;
}

.home-list {
  padding: 25px;
  margin: auto;
  font-size: 18px;
}

li:not(:last-child) {
  margin-bottom: 10px;
}

#text-link {
  color: black
}

@media only screen and (min-width: 1000px) {
  .home {
    
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .home-picture {
    margin: 0px;
  }

  .home-list {
    margin: 0px;
  }
}