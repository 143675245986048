.contact-component {
  padding-bottom: 50px;
  min-width: 250px;
}

.contact {
  /* background-color: var(--color3); */
  padding: 30px;
  max-width: 500px;
  margin: auto;
  margin-bottom: 30px;
}

.contact-title {
  font-size: 20px;
  text-align: center;
  color: var(--color6);
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-input {
  background-color: var(--color3);
  font-size: 15px;
  height: 30px;
  margin: 2px;
  color: var(--color1);
  border: none;
}

.contact-input::placeholder {
  color: var(--color1);
}

.contact-text-area {
  font-size: 15px;
  margin: 2px;
  resize: none;
  background-color: var(--color3);
  color: var(--color1);
  border: none;
}

.contact-text-area::placeholder {
  color: var(--color1);
}

.contact-submit-button {
  background-color: var(--color3);
  color: var(--color1);
  font-weight: 900;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 2.2), 0 6px 20px 0 rgba(0, 0, 0, 3.19);
  max-width: 200px;
  margin: auto;
  margin-top: 30px;
}

.contact-submit-button:hover {
  background-color: var(--color1);
  color: var(--color3);
}

.contact-submit-message {
  text-align: center;
  font-size: 25px;
  font-weight: 900;
}

.submit-message-success {
  color: green;
  display: none;
}


@media only screen and (min-width: 1000px) {

  .contact {
    padding: 50px;
    max-width: 600px;
  }

  .contact-title {
    font-size: 25px;
  }

  .contact-input {
    font-size: 20px;
    height: 40px;
  }

  .contact-text-area {
    font-size: 20px;
    height: 200px;
  }

}