.resources {
  margin-top: 30px;
display: flex;
/* flex-direction: column; */
justify-content: center;
flex-wrap: wrap;
}

.resource-button {
  height: 150px;
  width: 225px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

/* Border inside div */
  outline: 3px solid white;
  outline-offset: -7px;
}


.resources-img {
  max-width: 80%;
  max-height: 80%;
  margin-bottom: 10px;
}

.resource-title {
  font-weight: 600;
  padding-bottom: ;
}

@media only screen and (min-width: 1100px) {


.resource-button {
  height: 200px;
  width: 275px;
  margin: 10px;
}

}