.calendar {
  padding: 50px 50px 0px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  height: 75%;
  font-size: 12px;
}

.students {
  padding: 25px;
  display: flex;
  flex-direction: column;
}

.calendar-logo-img {
  height: 100%;
  object-fit: contain;
  width: 350px;
  margin: auto;
  text-align: center;
  border-radius: 100%;
}

button {
  background-color: var(--color4);
  color: var(--color1);
  border: none;
  margin: 25px;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
}

button:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 2.2), 0 6px 20px 0 rgba(0, 0, 0, 3.19);
  cursor: pointer;
}

#students-button {
  text-decoration: none;
  color: var(--color1);
}

a:hover {
  color: var(--color2)
}

.calendar-img-acknowledgment{
  margin-top: auto;
  text-align: end;
  color: var(--color0);
  padding: 10px;
  align-self: flex-end;
}

#calendar-acknow-link {
  color: black
}

@media only screen and (min-width: 1000px) {
  .students {
    display: flex;
    flex-direction: row;
  }
}