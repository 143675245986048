.certificates-component {
  text-align: center;
  padding-bottom: 200px;
}

.certificates-title {
  font-size: 40px;
  color: var(--color3);
  border-radius: 4px;
  background-color: var(--color3);
  color: var(--color1);
  
  max-width: 300px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.certificates {

}

.certificate {
margin-bottom: 75px;
display: flex;
flex-direction: column;
justify-content: center;

}

.certificate:hover {
  /* border: 1px solid black; */

}

.certificate-img {
  cursor: pointer;
}

.certificate-img:hover {
  opacity: 0.8;

}

.certificate-img-img {
  max-width: 150px;
  
}

.certificate-img-img:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 2.2), 0 6px 20px 0 rgba(0, 0, 0, 3.19);
  border-radius: 4px;
}

.certificate-name {
font-size: 20px;
margin: 5px 100px 5px 100px;
}

#certificate-name-link {
  text-decoration: none;
  color: var(--color0);
}

.certificate-name:hover {
  cursor: pointer;
  opacity: 0.8;
  
}

.--highlight {
  font-weight: 600;
}

.modal-dialog {
  max-width: fit-content;
}

.modal-content {
  background-color: transparent;
  border: 0px;
}

.certificate-img-in-modal {
max-width: 650px;
}

.horizontal-diploma {
  max-width: 550px;
}




@media only screen and (max-width: 800px) {
  .certificate-img-in-modal {
    max-width: 550px;
    }


}

@media only screen and (max-width: 550px) {
  .certificates-title {

    max-width: 300px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .modal-dialog {
    max-width: fit-content;
    margin: auto;
    padding-top: 250px;
  }
  
  .modal-content {
    background-color: transparent;
  }
  
  .certificate-img-in-modal {
  max-width: 300px;
  }

  .horizontal-diploma {
    max-width: 300px;
  }


}
/* 
@media only screen and (min-width: 1000px) {
.certificates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.certificates::after {
  content: "";
  flex: auto;
  
}

.certificate {
width: 350px;
}

.certificate-name {
  max-width: 450px;
  margin: 2px;
}
} */


@media only screen and (min-width: 1000px) {
  .certificates {
    display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: space-between;
  grid-gap: 20px;
  }
  
  .certificate {
  width: 300px;
  }
  
  .certificate-name {
    max-width: 300px;
    margin: 2px;
  }

  .horizontal-diploma {
    max-width: 1000px;
  }


  }