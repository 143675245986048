.resource-component {
  padding: 10px;
  /* margin-left: 70px; */
  margin: auto;
}

.resources-resource-title {
  padding: 15px;
  font-size: 20px;
}

.resources-resource-title:not:first-child {
  margin: 50px 10px 10px 10px;
}

.resource-paragraph {
  max-width: 500px;
  margin: 10px;
  font-size: 17px;
  text-align: justify;
}

.resource-images {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.resource-image {
  text-align: center;
  margin: 5px;
}

.resource-image-img {
  height: 50%;
  width: 150px;
  /* margin: auto; */
  margin-right: 40px;
  margin-left: 40px;
  text-align: center;
  cursor: pointer;
}

.resource-image-img:hover {
  opacity: 0.8;
}

.resources-bar-and-component {
  display: flex;
  flex-direction: column;
}

.resources-bar {
  /* position: fixed;
  left: 0;
  margin-right: 50px; */
  /* background-color: var(--color3); */
  display: flex;
  justify-content: center;
}

.resource-bar-button {
  height: 50px;
  width: 50px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* Border inside div */
  outline: 3px solid white;
  outline-offset: -7px;
}

.resource-bar-button:hover {
  /* If we change the icons for fontawesome we could change colors red/white on hover */
}

.resource-bar-img {
  max-width: 80%;
  max-height: 80%;
}

.--column-row {
  display: flex;

  flex-direction: row;
  max-width: 800px;
  text-align: justify;
  align-items: center;
  margin: 5px;
}

#text-link-movie {
  color: black;
  text-decoration: none;
}

#text-link-movie:hover {
  color: var(--color6);
}

#id-podcast {
  margin-bottom: 40px;
  font-size: 20px;
}

@media only screen and (max-width: 550px) {

.--text-center {
  text-align: center;
}

  .resource-component {
    margin-left: 0px;
  }

  .--column-row {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  .resource-images {
    justify-content: center;
  }

  .resource-image {
    min-height: 100px;
    display: flex;
    align-items: center;
  }

  .resources-bar-and-component {
    display: flex;
    flex-direction: column;
  }

  .resources-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px 0x 20px 0px;
    margin-right: 0px;
    position: relative;
  }

}

@media only screen and (min-width: 1000px) {

  .resource-component {
    /* margin-left: 70px; */
  }

  .resource-paragraph {
    max-width: 800px;
  }

  .resources-bar-and-component {
    justify-content: center;
  }

  .--not-centered {
    justify-content: flex-start;
  }

  .resource-bar-button {
    margin-right: 35px;
    margin-left: 35px;
  }

}